import React from 'react';
import GatsbyLink from 'gatsby-link';

const Link = ({ children, dark, to, className, ...other }) => {
  const internal = /^\/(?!\/)/.test(to);
  className = `${dark ? 'link-dark' : 'link'}${
    className ? ` ${className}` : ''
  }`;

  if (internal) {
    return (
      <GatsbyLink to={to} className={className} {...other}>
        {children}
      </GatsbyLink>
    );
  }

  return (
    <a href={to} className={className} {...other}>
      {children}
    </a>
  );
};

export default Link;
