import React from 'react';

// SVG
export const PleaseConfirmIllustration = () => {
  return <div>please confirm</div>;
};
// SVG
export const ThankYouIllustration = () => {
  return <div>thank you</div>;
};

// SVG
export const UnsubscribeIllustration = <div>unsub</div>;
