import React from 'react';
import { Link, StaticQuery, graphql } from 'gatsby';
import cx from 'classnames';
import MobileMenu from './MobileMenu';

import LogoText from '../LogoText';
import imgHead from '../../images/pbhead.png';

const NavLink = ({ className, dark, ...other }) => (
  <Link
    {...other}
    className={cx(className, 'font-semibold p-4 transition-all', {
      'text-bm-blue-500 hover:text-bm-blue-400': !dark,
      'text-white hover:text-teal-200': dark,
    })}
  />
);

const Header = ({ siteTitle, dark }) => {
  return (
    <header className="">
      <nav
        className={cx('transition-all', {
          'bg-bm-blue-100': !dark,
          'bg-bm-blue': dark,
          'text-white': dark,
          'text-bm-blue': !dark,
        })}
        style={{ height: '3.5rem' }}
      >
        <div className="flex items-start justify-around max-w-4xl mx-auto">
          <div className="flex-1 px-4 justify-center sm:flex hidden">
            <NavLink
              dark={dark}
              to="/blog"
              activeClassName="font-bold"
              aria-label="View blog page"
            >
              Blog
            </NavLink>
          </div>
          <div className="w-56 flex justify-center">
            <Link
              to="/"
              aria-label="go to homepage"
              className="bg-bm-blue absolute rounded-b-xl pl-6 pr-8 pt-3 pb-4 transition-all hover:scale-105"
            >
              <div className="whitespace-no-wrap flex justify-center items-center">
                <img src={imgHead} alt="Head" className="w-12 h-12 mr-4" />
                <LogoText />
              </div>
            </Link>
          </div>
          <div className="flex-1 px-4 justify-center hidden sm:flex">
            <NavLink
              dark={dark}
              to="/about"
              activeClassName="font-bold"
              aria-label="View blog page"
            >
              About
            </NavLink>
          </div>
        </div>
        <MobileMenu>
          <NavLink
            dark
            to="/blog"
            activeClassName="font-bold"
            aria-label="View blog page"
          >
            Blog
          </NavLink>
          <NavLink
            dark
            to="/about"
            activeClassName="font-bold"
            aria-label="View blog page"
          >
            About
          </NavLink>
        </MobileMenu>
      </nav>
    </header>
  );
};

const ConnectedHeader = props => (
  <StaticQuery
    query={graphql`
      query {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => (
      <Header siteTitle={data.site.siteMetadata.title} {...props} />
    )}
  />
);

export default ConnectedHeader;
