import * as React from 'react';
import cx from 'classnames';

const MutedPanel = ({ header, children, className }) => {
  return (
    <div
      className={cx(
        className,
        'bg-bm-blue-100 sm:rounded-lg p-4 px-6 font-sans text-base'
      )}
    >
      <h4 className="font-bold text-gray-600 font-sans mb-3 uppercase tracking-wider text-sm">
        {header}
      </h4>
      {children}
    </div>
  );
};

export default MutedPanel;
