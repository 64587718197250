import * as React from 'react';
import cx from 'classnames';
import Link from '../Link';
import MutedPanel from '../MutedPanel';

const TableOfContents = ({ className, items, slug, numFinalTop = 0 }) => {
  if (items == null || items.length === 0) {
    return null;
  }

  return (
    <MutedPanel header="Table of Contents" className={className}>
      <ul className="text-gray-500">
        {slug && (
          <li>
            <Link to={`/${slug}`}>Intro</Link>
          </li>
        )}
        {items.map((item, i) => {
          const hasRootItem = !!item.url;
          const hasSubItems = item.items && item.items.length;
          const isLastItem = i === items.length - 1;
          let subItems = item.items;
          let topItems;
          if (hasSubItems) {
            if (isLastItem && numFinalTop !== 0) {
              subItems = item.items.slice(0, subItems.length - numFinalTop);
              topItems = item.items.slice(numFinalTop);
            } else if (!hasRootItem) {
              subItems = undefined;
              topItems = item.items;
            }
          }
          return (
            <React.Fragment key={i}>
              <li className={cx({ 'mb-4': !isLastItem }, 'leading-tight')}>
                {hasRootItem && <Link to={item.url}>{item.title}</Link>}
                {subItems && (
                  <ul className="mt-4 list-disc list-inside">
                    {subItems.map((item2, j) => (
                      <li
                        key={`2-${j}`}
                        className={cx(
                          {
                            'mb-4': !(
                              isLastItem && j === item.items.length - 1
                            ),
                          },
                          'leading-tight'
                        )}
                      >
                        <Link to={item2.url}>{item2.title}</Link>
                      </li>
                    ))}
                  </ul>
                )}
              </li>
              {topItems &&
                topItems.map((item2, j) => (
                  <li
                    key={`2-${j}`}
                    className={cx(
                      { 'mb-4': !(isLastItem && j === topItems.length - 1) },
                      'leading-tight'
                    )}
                  >
                    <Link to={item2.url}>{item2.title}</Link>
                  </li>
                ))}
            </React.Fragment>
          );
        })}
      </ul>
    </MutedPanel>
  );
};

export default TableOfContents;
