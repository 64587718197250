import React from 'react';

import Code from './Code';
import TableOfContents from './TableOfContents';

import styles from './mdx.module.css';

/* eslint-disable jsx-a11y/heading-has-content */
export default {
  h1: props => (
    <h1
      className={`${styles.caseBg} mdx-header mt-32 mb-16 leading-tight font-normal font-sans text-2xl sm:text-3xl bg-bm-blue lg:rounded px-4 py-2 text-center tracking-wide text-white`}
      {...props}
    />
  ),
  h2: props => (
    <h2
      className="mdx-header px-4 max-w-3xl mx-auto mt-16 mb-4 leading-tight font-black font-sans text-2xl sm:text-4xl"
      {...props}
    />
  ),
  h3: props => (
    <h3
      className="mdx-header px-4 max-w-3xl mx-auto mt-12 mb-4 leading-tight font-extrabold font-sans text-xl sm:text-2xl"
      {...props}
    />
  ),
  h4: props => (
    <h4
      className="mdx-header px-4 max-w-3xl mx-auto mt-12 mb-4 leading-tight font-bold font-sans text-lg sm:text-xl"
      {...props}
    />
  ),
  a: props => <a className="link" {...props} />,
  p: ({ children, ...other }) => {
    let isImage = false;
    React.Children.forEach(children, child => {
      if (
        child.props &&
        child.props.className === 'gatsby-resp-image-wrapper'
      ) {
        isImage = true;
      }
    });
    if (isImage) {
      return (
        <div className="max-w-6xl mx-auto my-12" {...other}>
          {children}
        </div>
      );
    }

    return (
      <p className="px-4 max-w-3xl mx-auto mb-8" {...other}>
        {children}
      </p>
    );
  },
  CodeCaption: props => (
    <div
      className="text-gray-600 text-center max-w-2xl -mt-10 text-sm mx-auto mb-12"
      {...props}
    />
  ),
  ImageCaption: props => (
    <div
      className="text-gray-600 text-center max-w-2xl -mt-10 text-sm mx-auto mb-12"
      {...props}
    />
  ),
  pre: preProps => {
    const props = preToCodeBlock(preProps);
    // if there's a codeString and some props, we passed the test
    if (props) {
      return <Code {...props} />;
    } else {
      // it's possible to have a pre without a code in it
      return (
        <pre
          className="px-4 max-w-3xl mx-auto text-code mb-8 text-gray-700 overflow-auto"
          {...preProps}
        />
      );
    }
  },
  ul: props => (
    <ul
      className="px-4 max-w-3xl mx-auto mb-8 list-disc list-inside"
      {...props}
    />
  ),
  li: props => <li className="mb-2" {...props} />,

  ol: props => (
    <ul
      className="px-4 max-w-3xl mx-auto mb-8 list-decimal list-inside"
      {...props}
    />
  ),

  table: props => (
    <div className="overflow-auto px-4 max-w-3xl mx-auto my-12 text-xs sm:text-base leading-tight">
      <table className="font-sans text-gray-700 mx-auto" {...props} />
    </div>
  ),
  td: props => <td className="px-4 py-1" {...props} />,
  th: props => <th className="px-4 py-1 text-left" {...props} />,

  TableOfContents: props => (
    <TableOfContents className="px-4 max-w-3xl mx-auto mb-8" {...props} />
  ),
};

// lifted this from mdx-utils
// it doesn't compile it's code and this busted IE, so I'm just vendoring it.
function preToCodeBlock(preProps) {
  if (
    // children is code element
    preProps.children &&
    // code props
    preProps.children.props &&
    // if children is actually a <code>
    preProps.children.props.mdxType === 'code'
  ) {
    // we have a <pre><code> situation
    const {
      children: codeString,
      className = '',
      ...props
    } = preProps.children.props;

    const matches = className.match(/language-(?<lang>.*)/);

    return {
      codeString: codeString.trim(),
      className,
      language:
        matches && matches.groups && matches.groups.lang
          ? matches.groups.lang
          : '',
      ...props,
    };
  }
}
