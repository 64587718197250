import React, { useState } from 'react';
import styles from './MobileMenu.module.css';

const Toggle = ({ children }) => {
  const [isToggledOn, setToggle] = useState(false);
  const toggle = () => setToggle(!isToggledOn);

  return (
    <div className="sm:hidden flex justify-end h-full">
      <button
        onClick={toggle}
        aria-label={`${isToggledOn ? 'close menu' : 'open menu'}`}
        className={`p-4 pr-6 ${styles.navToggleBtn} ${
          isToggledOn ? styles.toggled : ''
        }`}
      >
        <div className={styles.navToggleBtnInner} />
      </button>
      {isToggledOn && (
        <div className="absolute inset-0 bg-bm-blue">
          <div className="flex flex-col items-center h-full justify-center text-2xl">
            {children}
          </div>
        </div>
      )}
    </div>
  );
};

export default Toggle;
