import React from 'react';
import SubscribeForm from './Forms/Subscribe';
import { Twitter, GitHub } from './Social';
import LogoText from './LogoText';
import { Link } from 'gatsby';

const Footer = ({ author, noSubscribeForm }) => (
  <footer className="bg-gray-900 text-white p-10">
    {false && !noSubscribeForm && <SubscribeForm />}
    <div className="flex flex-col items-center">
      <div className="mb-8">
        <Link to="/" aria-label="Beshai Makes">
          <LogoText />
        </Link>
      </div>
      <div className="flex mb-8">
        <Twitter dark className="mx-2" />
        <GitHub dark className="mx-2" />
      </div>
      <div className="text-gray-600 text-sm">
        <a href="https://peterbeshai.com">Peter Beshai</a> {'\u00A9 '}
        {new Date().getFullYear()}
      </div>
    </div>
  </footer>
);

export default Footer;
