import React from 'react';
import Helmet from 'react-helmet';
import { graphql } from 'gatsby';
import { MDXProvider } from '@mdx-js/react';
import mdxComponents from './mdx/mdx';
import Header from './Header/Header';
import config from '../../config/website';
import Footer from '../components/Footer';

export default ({
  site,
  frontmatter = {},
  children,
  noFooter,
  noSubscribeForm,
  darkNav,
}) => {
  const {
    description: siteDescription,
    keywords: siteKeywords,
  } = site.siteMetadata;

  const {
    keywords: frontmatterKeywords,
    description: frontmatterDescription,
  } = frontmatter;

  const keywords = (frontmatterKeywords || siteKeywords).join(', ');
  const description = frontmatterDescription || siteDescription;

  return (
    <div className="min-h-screen flex flex-col">
      <Helmet
        title={config.siteTitle}
        meta={[
          { name: 'description', content: description },
          { name: 'keywords', content: keywords },
        ]}
      >
        <html lang="en" />
        <noscript>This site runs best with JavaScript enabled.</noscript>
      </Helmet>
      <Header dark={darkNav} />
      <MDXProvider components={mdxComponents}>
        <React.Fragment>{children}</React.Fragment>
      </MDXProvider>
      {!noFooter && (
        <Footer
          author={site.siteMetadata.author.name}
          noSubscribeForm={noSubscribeForm}
        />
      )}
    </div>
  );
};

export const pageQuery = graphql`
  fragment site on Site {
    siteMetadata {
      title
      description
      author {
        name
      }
      keywords
    }
  }
`;
