import * as React from 'react';
import cx from 'classnames';

const Container = ({ text, children, className }) => {
  return (
    <div
      className={cx(className, 'mx-auto', {
        'max-w-3xl': text,
        'max-w-4xl': !text,
      })}
    >
      {children}
    </div>
  );
};

export default Container;
