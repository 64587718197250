import React from 'react';
import Markdown from 'react-markdown';
import Link from '../Link';

export default ({
  illustration,
  title,
  body,
  note,
  fullscreen = false,
  articleTitle,
  articleSlug,
}) => (
  <div>
    <div>{illustration}</div>
    <h2>{title}</h2>
    {body && <Markdown>{body}</Markdown>}
    {note && (
      <div>
        <span>
          <Markdown>{note}</Markdown>
        </span>
      </div>
    )}
    {articleTitle && (
      <div>
        <Link to={`/${articleSlug}`}>{articleTitle}</Link>
      </div>
    )}
  </div>
);
